@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
body {
  margin: 0;
  background-color: #00172e !important;
  font-family: "Nunito Sans", sans-serif !important;
  overflow-x: hidden;
  background: url(./assets/images/dashboard/bg.png) no-repeat;
  background-size: cover;
  color: #fff !important;
}

.main_navbar {
  background-color: #00172e;
  /* opacity: 0.55; */
  border-bottom: 1px solid #003060;
}
.main_navbar h5 {
  font-weight: 700;
  font-size: 30px;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 0px;
}
.primary_btn {
  border-radius: 100px;
  padding: 10px 30px;
  box-shadow: 41px 32px 104px -11px #004589,
    inset 13px 12px 22px 0 rgba(169, 172, 0, 0.57);
  background: linear-gradient(180deg, #d1a10c 0%, #f6dd07 100%);

  border: 1px solid transparent;
  font-weight: 700;
  font-size: 18px;
  text-transform: capitalize;
  color: #001e3b;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.primary_btn:hover {
  background: linear-gradient(180deg, #f6dd07 0%, #d1a10c 100%);
}
.main_navbar .navbar-toggler-icon {
  filter: contrast(0.5) brightness(5.5);
}
.main_navbar .navbar-toggler:focus {
  box-shadow: none;
  outline: 0;
}
.offcanvas {
  background-color: #00172e !important;
}
.offcanvas-header .btn-close {
  filter: contrast(0.5) brightness(10);
}
.main_navbar .btn-close:focus {
  box-shadow: none;
  outline: 0;
}
.section_features {
  padding-bottom: 140px !important;
}
/* home_section */
.home_section::before {
  content: "";
  background: url(./assets/images/banner_bg_2.png);
  background-repeat: no-repeat;

  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.home_section {
  background: url(./assets/images/banner_bg_1.png) center center;
  background-repeat: no-repeat;
  padding-top: 96px;
  padding-bottom: 152px;
  background-size: cover;
  position: relative;
  z-index: 1;
}
.h5_tag {
  font-weight: 700;
  font-size: 36px;
  color: #fff;
  margin-bottom: 0px;
}
.h5_tag span {
  font-weight: 700;
  color: #f3d907;
}

.home-banner-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  color: #d8ebff;
  margin-bottom: 0px;
}
.home-banner-content .h5_tag {
  font-family: "Outfit" !important;
  font-weight: 600 !important;
  font-size: 60px;
}
.home-banner-content .h5_tag span {
  font-weight: 600 !important;
  background: linear-gradient(90deg, #f6dd07 0%, #d1a10c 100%) !important;
  background-clip: text !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent !important;
}
/* features */
.home_section_features {
  position: relative;
}
/* .home_section_features::before {
  content: "";
  background: url(./assets/images/banner_bg_3.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
} */
.home_section_features {
  /* s */
  background: url(./assets/images/sec_2_bg.png) no-repeat center;
  background-size: cover;
  padding-bottom: 50px;
}

.home_features_content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  color: #d8ebff;
  margin: auto;
  max-width: 36%;
}
.home_features h6 {
  font-weight: 700;
  font-size: 26px;
  color: #89c4ff;
  margin-bottom: 0px;
}
.home_features p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #d8ebff;
  margin-bottom: 0px;
}
.features_list {
  list-style-type: none;
  width: 40%;
  margin-left: 30px;
}
.features_list li {
  position: relative;
}
.features_list li::before {
  content: "";
  position: absolute;
  height: 170px;
  width: 1px;
  background-color: #00458a;
  top: 5px;
  left: -40px;
  padding: 0px;
  margin: 0px !important;
}
.features_list li:last-child::before {
  content: "";
  position: absolute;
  height: 90px;
}
.features_list li:hover ::before {
  content: "";
  position: absolute;
  height: 90px;
  width: 3px;
  background-color: #0080ff;
  top: 5px;
  left: -40px;
  transition: 5.5;
}
/* web3 content */
.home_web3_projects {
  padding-top: 50px;
}
.home_web3_projects p {
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;
  text-align: center;
  color: #fff;
  margin-bottom: 0px;
  margin: auto;
  max-width: 39%;
}
.web3_text p {
  max-width: 60%;
  margin: auto;
}
.home_web3_projects {
  position: relative;
  padding-bottom: 50px;
}
.home_web3_projects::before {
  content: "";
  position: absolute;
  /* background: url(./assets/images/banner_bg_4.png); */
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* z-index: -1; */
}
/* slider */
.slider_2 .slick-dots {
  bottom: 0px;
  position: static;
}
.home-blog-slider::before {
  content: "";
  position: absolute;
  /* background: url(./assets/images/banner_bg_5.png); */
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.5;
}
.home-blog-slider {
  position: relative;
  padding-bottom: 54px;
}
.home-blog-slider .home-content-secondary h3 {
  text-align: left;
  color: #fff;
}
.home-blog-slider .home-content-secondary p {
  font-weight: 400;
  font-size: 18px;
  line-height: 200%;
  text-align: center;
  color: #d8ebff;
}

.home-blog-slider .home-blog-card p {
  color: #fff;
}
.home-blog-slider .home-blog-card {
  border: 1px solid transparent;
  border-radius: 20px;
  padding: 50px 20px 50px 20px;
  /* height: 436px; */
  /* z-index: 10; */
  position: relative;
  background: #00172e;
}
.home-blog-slider .home-blog-card::before {
  content: "";
  background: linear-gradient(
    121deg,
    rgba(0, 69, 137, 1) 0%,
    rgba(0, 23, 46, 1) 45%,
    rgba(0, 69, 137, 1) 100%
  );

  border-radius: 20px;
  top: -4px;
  bottom: 0px;
  right: 0px;
  left: -3px;
  height: 102%;
  width: 102%;
  z-index: -10;
  position: absolute;
}

.home-blog-slider .slider_1 .home-blog-card {
  margin: 50px 12px 12px;
}
.home-blog-card {
  text-align: center;
}
.home-blog-card span {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #d8ebff;
}
.home-blog-card h3 a {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #89c4ff;
  text-decoration: none;
}
.home-blog-card p {
  background-color: #001224;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  max-width: 50%;
  margin: auto;
  align-items: center;
}
.home-blog-card p img {
  padding-right: 10px;
}
.slider_1 .slick-next {
  color: #e96230;
  opacity: 1 !important;
  border-radius: 50px;
  background: url(./assets/images/right.png) no-repeat scroll center center !important;
  padding: 24px;
  margin-right: -32px;
}
.slider_1 .slick-prev {
  color: #e96230;
  opacity: 1 !important;
  border-radius: 50px;
  background: url(./assets/images/left.png) no-repeat scroll center center !important;
  padding: 24px;
  margin-left: -32px;
}

.slider_1 .slick-next::before {
  content: "";
}
.slider_1 .slick-prev::before {
  content: "";
}

.slider_1 .slick-slide .home-blog-card:hover {
  background: #001e3b !important;
  border: 1px solid transparent;
}

.slider_1 .home-blog-card:hover h3 a {
  color: #ddac0f;
}
.slider_1 .home-blog-card:hover .slider_img {
  filter: sepia(1) saturate(10) hue-rotate(11deg);
}

.slider_1 .home-blog-card:hover::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -2px;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    123deg,
    rgb(247 236 236) -29%,
    rgba(0, 69, 137, 1) 20%,
    rgba(0, 23, 46, 1) 45%
  );
  box-shadow: -5px 14px 14px rgba(0, 0, 0, 0.5);
  z-index: -1;
}

/* smart */
.home_smart_tech {
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  background: rgb(0 19 38 / 50%);
}
.smart_section {
  position: relative;
}

.smart_section .col{
padding: 0px !important;
}
.smart_img {
  position: absolute;
  z-index: 4;
  top: 52%;
  bottom: auto;
  left: 50%;
  right: auto;
  transform: translate(-50%, -50%);
}
.smart_img img {
  /* max-width: 100px !important; */
}
.smart_card_content {
  width: 100% !important;
  /* padding: 45px 60px 40px 40px !important; */
  position: relative;
  overflow: hidden;
  gap: 10px;
  height: 100% !important;
  transition: .5s;
  margin-top: 25px;
}

.smart_content {
  align-items: flex-start;
  transition: .5s;
}
.smart_img_sec {
  z-index: 2;
  position: relative;
}
.smart_card_text {
  z-index: 3;
  position: relative;
  margin-top: 50px;
}
.smart_card_content_1 {

  align-items: start;
  justify-content: end;
}
.smart_card_content_2 {
 
  justify-content: end;
}
.smart_card_content_3 {
 
  align-items: flex-start;
  justify-content: end;
}
.smart_card_content h6 {
  font-weight: 700;
  color: #f3d907;
  font-size: 36px;
  margin-bottom: 0px;
}
.smart_card_content p {
  font-weight: 700;
  color: #89c4ff;
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 10px;
}
.home_smart_tech_1 {
  /* display: flex;
  align-items: flex-start;
  justify-content: space-between; */
}
.home_smart_content {
  /* width: 30%; */
}
.home_smart_content .h5_tag {
  margin-bottom: 20px;
}
.home_smart_content p {
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  color: #fff;
}
.smart_hover {
  opacity: 1;
  background-color: rgb(0 21 42 / 28%);
  width: 100%;
  height: 100%;
  transition: opacity .5s ease-in !important;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
  border-radius: 25px;
  /* z-index: -1; */
}
.smart_card_content:hover .smart_hover {
  display: none;
  transition: .5s !important;
  opacity: 0.5;
}
/* accordion */
.home_accordion {
  position: relative;
  padding-bottom: 35px;
  padding-top: 30px;
  overflow: hidden;
}
.home_accordion::before {
  content: "";
  position: absolute;
  /* background: url(./assets/images/banner_bg_8.png); */
  background-repeat: no-repeat;
  background-size: cover;
  top: -300px;
  bottom: 0;
  right: 0;
  left: -16px;
}
.home_accordion .accordion-item:first-child {
  border-top: 1px solid #00264d;
}
.home_accordion .accordion-item {
  background: #eeeff200;
  border: 0;
  border-radius: 0px;
  margin-bottom: 0px;
  margin-top: 5px;
  transition: 0.5s !important;
  position: relative;
  border-bottom: 1px solid #00264d;
}
.home_accordion .accordion {
  background-color: transparent !important;
  width: 50%;
  margin: auto;
}
.home_accordion .accordion-button:not(.collapsed) {
  background-color: transparent !important;
  box-shadow: none;
  transition: 10s !important;
}
.home_accordion .accordion-button {
  background-color: transparent !important;
  transition: 10s !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
.home_accordion .accordion-item:not(.collapsed) {
  border: 0;
  border-color: transparent;
}
.home_accordion .accordion-button {
  background: transparent;
  box-shadow: none !important;
  padding: 30px;
  padding-right: 90px;
  color: #000 !important;
  position: unset;
}
.home_accordion .accordion-button h5 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  color: #89c4ff;
  font-family: "Nunito" !important ;
}
.home_accordion .accordion-button::after {
  content: "";
  background: url("./assets/images/plus.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 30px;
  top: 35px;
  width: 22px;
  height: 22px;
  transition: transform 1.5s !important;
}

.accordion-button.collapsed::after {
  background-image: none;
}

.home_accordion [aria-expanded="true"] .accordion-button::after {
  transform: rotate(44deg);
  filter: contrast(0.5) brightness(5);
}
.home_accordion [aria-expanded="true"] .accordion-button h5 {
  color: #F3D907;
}
.home_accordion [aria-expanded="true"] .accordion-button img{
  filter: hue-rotate(209deg) brightness(284%);

}
.home_accordion [aria-expanded="true"] .accordion-button::after{
  filter: hue-rotate(209deg) brightness(144%);
}
.home_accordion [aria-expanded="false"] .accordion-button::after {
  transform: rotate(0deg);
  
}

.home_accordion .accordion-item .para span {
  font-weight: 600;
}
.home_accordion .accordion-body {
  padding: 30px;
  padding-right: 90px;
  padding-top: 0px;
}
.home_accordion .accordion-body p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  color: #d8ebff;
}

/* footer */
.main-footer {
  padding: 12px 0;
  background-color: #001325;
  /* margin-top: 50px; */
  /* bottom: 0;
  position: fixed; */
  width: 100%;
  position: relative;
  z-index: 2;
}
.footer-social {
  display: flex;
  align-items: center;
}
.footer-card h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 200%;
  color: #6fa3d8;
  margin-bottom: 0px;
}
.footer-social span {
  background: #001e3b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.footer-social span:hover {
  background: linear-gradient(
    90deg,
    rgba(209, 161, 12, 1) 50%,
    rgba(246, 221, 7, 1) 100%
  );
}
.footer-social span:hover img {
  filter: contrast(0.5) brightness(0);
}
.footer-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-card ul {
  margin-bottom: 0;
  padding-left: 0px;
}
.footer-card .footer_list {
  display: flex;
  gap: 10px;
}
.footer-social a {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  display: inline-flex;
  align-items: center;
  margin: 0 7px;
  color: #ffffff;
}

.footer-social a:hover {
  text-decoration: none;
}
.footer-card p {
  font-weight: 400;
  color: #a2b6cb;
  margin: 0;
  font-size: 16px;

  line-height: 18px;
}
.staked_progress {
  width: 100%;
}
.staked_progress .progress {
  background: #00376e;
  height: 12px;
}
.staked_progress .progress-bar {
  background: #e8cc07;
}
.staked_progress_flex {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.staked_progress_flex label {
  font-size: 12px;
}
.dashboard_tree_sec .primary_btn {
  font-size: 15px;
  padding: 6px 15px;
}
.dashboard_tree_sec .primary_btn i {
  font-size: 24px;
  line-height: 0;
  margin-right: 2px;
  margin-left: -2px;
}

@media (max-width: 1399px) {
  .slider_1 .slick-prev {
    margin-left: -14px;
  }
  .slider_1 .slick-next {
    margin-right: -14px;
  }
  .home_section_features::before {
    background-position: center;
  }
  .home_smart_tech_1 {
    flex-direction: column;
  }
  .home_smart_content {
    width: 100%;
    text-align: center;
  }
  .smart_section {
    margin: auto;
  }
  .home_accordion .accordion {
    width: 70%;
  }
  .dashboard_ref_link {
    gap: 10px;
  }
  .dashboard_user_account .p_tag {
    white-space: wrap !important;
    /* padding-bottom: 0px !important; */
    word-break: break-word;
  }
  .dashboard_user_account_1 {
    padding: 14px 16px !important;
    gap: 6px !important ;
  }
  .smart_card_content{
    width: auto !important;
    padding: 0 !important;
    height: auto !important;
  
  }
  .smart_img {
    left: 50%;
  }
  .bg_color {
    bottom: 24px !important;
    left: 32px !important;
  }
}
@media (max-width: 1199px) {
  .home_accordion .accordion {
    width: 100%;
  }
  .home_smart_content h5 {
    text-align: center;
  }

  .home_accordion::before {
    background: none;
  }
  .inner_nav_btn {
    text-align: center;
  }
  .home_features_content p {
    max-width: 50%;
  }
  .home_web3_projects p {
    max-width: 53%;
  }
  .web3_text p {
    max-width: 76%;
  }
  .smart_card_content img{
    width: 100%;
  }
  .bg_color {
    bottom: 28px !important;
    left: 48px !important;
  }
 
}
@media (max-width: 991px) {
  .h5_tag {
    text-align: center;
  }
  .home-banner-content p {
    text-align: center;
  }
  .home_btn {
    text-align: center;
  }
  .web3_text p {
    max-width: 100% !important;
  }
  .home_section {
    background-position: right;
  }
  .features_list {
    width: 63%;
  }
  .home_section_features {
    padding-bottom: 20px;
  }
  .home_section_features::before {
    background: none;
  }
  .home_section {
    padding-top: 0px;
    padding-bottom: 68px;
  }

  .footer-card {
    flex-direction: column;
    gap: 30px;
  }
  .footer-card h6 {
    text-align: center;
  }
  .smart_img {
    /* position: absolute; */
  }
  .smart_card_content {
    /* width: 340px !important;
    height: 310px !important; */
  }
  .home-blog-slider {
    padding-bottom: 0px;
  }
  .home_accordion {
    padding-top: 0px;
    padding-bottom: 30px;
  }
  .home_section::before {
    background: #00000078;
  }
  .home_features_content p {
    max-width: 58%;
  }
  .home_web3_projects p {
    max-width: 71%;
  }
  .bg_color {
    left: 30px !important;
  }
  .dashboard_Royalty_account{
    text-align: center;
  }
  
}
@media (max-width: 767px) {
  .home-banner-content .h5_tag {
    font-size: 34px;
  }

  .smart_section {
    width: 100% !important;
  }
 
  .smart_card_content h6 {
    font-size: 32px;
  }
  .smart_card_content p {
    font-size: 22px;
  }
  
  .smart_img {
    top: 50%;
  }
  .home_section_features {
    background: url(./assets/images/banner_bg_mobile1.png) no-repeat center;
    background-size: contain;
  }
  .home_section_features {
    position: relative;
  }
  .home_section_features::before {
    content: "";
    position: absolute;
    background: rgb(0 23 46 / 78%);
    width: 100%;
    height: 100%;
  }

  .features_list {
    width: 100%;
    text-align: center;
    padding-left: 0px;
  }
  .features_list li::before {
    background: none;
  }
  .features_list li:last-child::before {
  background: none;
  }
  .features_list li:hover ::before {
background: none;
  }
  .home_web3_projects p {
    max-width: 100% !important;
  }
  .home_smart_content p {
    font-size: 18px;
  }
  .smart_img {
    top: 1px !important;
  }
  .smart_card_content{
    margin-top: 15px;
  }
}

@media (max-width: 575px) {
  .home-blog-slider .home-blog-card {
    padding: 25px 20px 25px 20px;
    /* height: 380px; */
  }
  .home_section {
    padding-top: 0px;
    padding-bottom: 40px;
  }
  .h5_tag {
    font-size: 28px;
  }
  .home_accordion .accordion-button h5 {
    font-size: 16px;
  }
  .home_accordion .accordion-body p {
    font-size: 16px;
  }
  .home_accordion .accordion-body {
    padding: 15px;
  }
  .home_accordion .accordion-button {
    padding: 20px 80px 20px 20px;
  }
  .home_accordion [aria-expanded="true"] .accordion-button h5 {
    font-weight: 700;
    font-size: 16px;
    color: #fff;
  }
  .home_web3_projects p {
    font-size: 16px;
  }
  .home-blog-slider .home-content-secondary p {
    font-size: 16px;
  }
  .home_features {
    margin-top: 12px !important;
  }
  .home_features_content p {
    font-size: 16px;
  }
  .footer-card p {
    font-size: 14px;
  }
  .features_list {
    margin-left: 0px;
  }
  .home_features h6 {
    font-size: 22px;
  }
  .slider_1 .slick-prev {
    margin-left: 18px;
    z-index: 1;
  }
  .slider_1 .slick-next {
    margin-right: 18px;
  }
  .home_web3_projects {
    padding-top: 20px !important;
  }
  /* .main-footer{
    margin-top: 20px;
  } */
  .h5_tag span {
    font-weight: 500;
  }
  .features_list li::before {
    left: -20px;
  }
  .features_list li:hover ::before {
    left: -20px;
  }
 
  .smart_card_content h6 {
    font-size: 26px;
  }
  .smart_card_content p {
    font-size: 13px;
  }
  .smart_card_content {
    gap: 15px;
  }
  .bg_color {
    left: 25px !important;
    bottom: 20px !important;
  }
 
  
  
  .home_features_content p{
    max-width: 100%;
  }
}
/* @media (max-width: 435px) {
  .smart_img {
    top: 52% !important;
  }
}
@media (max-width: 379px) {
  .smart_img {
    top: 55% !important;
  }
} */
/* dashboard */
.inner_navbar {
  display: flex;
  align-items: center;
  gap: 10px;
}
.navbar_right .dropdown .dropdown-toggle {
  border-radius: 100px;
  padding: 10px 15px;
  box-shadow: 41px 32px 104px -11px #004589,
    inset 13px 12px 22px 0 rgba(169, 172, 0, 0.57);
  background: linear-gradient(180deg, #d1a10c 0%, #f6dd07 100%);
  border: 1px solid transparent;
  font-weight: 700;
  font-size: 18px;
  text-transform: capitalize;
  color: #001e3b;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.navbar_right .dropdown-toggle::after {
  display: none;
}
.navbar_right .dropdown .btn:hover {
  background: linear-gradient(180deg, #f6dd07 0%, #d1a10c 100%);
  border: 1px solid transparent;
}
.navbar_right .btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: #001e3b !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.navbar_right .dropdown-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar_right .dropdown-body {
  padding: 5px;
  border-top: 1px solid #e5e8ec;
  display: none;
  background-color: red;
}

.navbar_right .dropdown-body.open {
  display: block;
}
.navbar_right .dropdown-menu {
  background-color: #00458a;
}
.navbar_right .dropdown-item {
  padding: 5px 10px;
  color: #fff !important;
}
.navbar_right .dropdown_menu_item .dropdown-menu {
  background-color: #003568; /* Customize the background color as needed */
  border: 1px solid rgba(0, 0, 0, 0.125); /* Example border style */
  color: #ffff;
}
.navbar_right .dropdown-item:hover {
  cursor: pointer;
  background-color: #002548 !important;
}

.navbar_right .dropdown-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.navbar_right .dropdown-item-dot.selected {
  opacity: 1;
}
.primary_btn .dropdown-item {
  font-weight: 700;
  font-size: 18px;
  color: #001e3b;
}

.dashboard_Section {
  padding: 80px 0px 0px;
  position: relative;
}
.dashboard_Section::before {
  /* content: "";
  position: absolute;
  background: url(./assets/images/dashboard/circle_02.png) top right;
  background-repeat: no-repeat;
top: -150px;
right: 0;
  width: 100%;
  height: 100%;
  z-index: -1; */
}
.mobile_img_none{
display: flex;
align-items: center;
gap: 10px;
}
.mobile_img_none img{
  display: none;
}
.dashboard_header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(360deg, #001224 0%, #001224 25%, #001c37 50.5%, #063b70 75%, #007fff 100%);
  border-left: 1px solid #00305f;
border-right: 1px solid #00305f;
border-top: 1px solid #00305f;
border-radius: 16px 16px 0 0;
padding: 16px;
}
.dashboard_page .h2_tag {
  font-weight: 600 ;
  font-size: 24px ;
  color: #e8cc07;
  margin-bottom: 0px;
  font-family: "Outfit", sans-serif;;
}
/* .dashboard_page .h2_tag span {
  font-weight: 700;
  font-size: 36px;
  color: #e8cc07;
} */
.dashboard_header .rotate {
  margin-right: 0px !important;
}
/* .dashboard_table img {
  margin-right: 0px !important;
} */
.dashboard_user_detail {
  border: 1px solid #00305f;
  border-radius: 8px;
  padding: 16px;
    background: radial-gradient(93.99% 72.86% at 50% 0%, rgb(6, 44, 82) 0%, rgb(0, 18, 36) 100%);
    height: 232px;
}
.dashboard_user {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #00305f;
  padding: 0px 0px 16px 0px;
}
.dashboard_user h6 {
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  margin-bottom: 5px;
}
.dashboard_user h6 span {
  font-weight: 700;
  font-size: 18px;
  color: #f3d907;
  margin-bottom: 0px;
}
.dashboard_user p {
  font-weight: 400;
  font-size: 14px;
  color: #89c4ff;
  margin-bottom: 0px;
}
.dashboard_user_detail .form-label {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
}
.dashboard_user_detail .input-group {
  border-radius: 10px;
  padding: 8px;
  background: #00172E;
}
.dashboard_user_detail .form-control {
  border: none;
  background-color: transparent;
  color: #fff !important;
}
.dashboard_user_detail .input-group-text {
  border: none;
  background-color: transparent;
}
.dashboard_user_detail .form-control:focus {
  color: #fff;
  background-color: transparent;
  border-color: none;
  outline: 0;
  box-shadow: none;
}
.dashboard_user_detail .custom-placeholder::placeholder {
  color: #89c4ff;
  font-size: 13px;
  font-weight: 400;
}
.dashboard_user_detail .input-group span {
  cursor: pointer;
}
.dashboard_user_account {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 20px; */
  border: 1px solid #00305f;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  background: radial-gradient(93.99% 72.86% at 50% 0%, rgb(6, 44, 82) 0%, rgb(0, 18, 36) 100%);
  height: 232px;
  justify-content: space-between;
}
.dashboard_user_account1::before {
  width: 103.5% !important;
}

.dashboard_user_account_1 {
  gap: 10px;
  padding: 30px 20px;
 
}
.dashboard_user_account span {
  font-weight: 400;
  font-size: 15px;
  color: #4fc056;
  display: block;
  padding-top: 8px;
}
.dashboard_user_account .p_tag {
  font-weight: 400;
  font-size: 15px;
  color: #fd1731;
  margin-bottom: 0px;
  white-space: nowrap;
  border-bottom: 1px solid #002d59;
  padding-bottom: 15px;
  width: 100%;
}
.dashboard_user_account span{
  border-bottom: 1px solid #002d59;
  padding-bottom: 15px;
  width: 100%;
}
.dashboard_Royalty_account{
  gap: 16px !important;
  justify-content: flex-start;
}
.dashboard_user_account_1:first-child.p_tag {
  font-weight: 400;
  font-size: 15px;
  color: #fd1731;
  margin-bottom: 0px;
  white-space: nowrap;
  border-top: 1px solid #002d59;
  display: block;
  padding-bottom: 10px;
}
.dashboard_users {
  position: relative;
  height: 170px;
}
/* .dashboard_users::before {
  content: "";
  background: linear-gradient(
    130deg,
    rgba(0, 69, 137, 1) 0%,
    rgba(0, 23, 46, 1) 45%,
    rgba(0, 69, 137, 1) 100%
  );
  border-radius: 20px;
  top: -2px;
  bottom: 0px;
  right: 0px;
  left: -3px;
  height: 102%;
  width: 101.5% !important;
  z-index: -10;
  position: absolute;
}
.dashboard_user_account::before {
  content: "";
  background: linear-gradient(
    130deg,
    rgba(0, 69, 137, 1) 0%,
    rgba(0, 23, 46, 1) 45%,
    rgba(0, 69, 137, 1) 100%
  );
  border-radius: 20px;
  top: -2px;
  bottom: 0px;
  right: 0px;
  left: -3px;
  height: 102%;
  width: 102%;
  z-index: -10;
  position: absolute;
} */
.dashboard_user_account h6 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0px;
}
.dashboard_user_account p {
  font-size: 26px;
  font-weight: 800;
  color: #f3d907;
  margin-bottom: 0px;
  white-space: wrap;
  word-break: break-all;
}
/* section_1 */
.dashboard_Section_1 {
  padding-bottom: 50px;
  padding-top: 50px;
  position: relative;
}
.dashboard_Section_1::before {
  /* content: "";
  position: absolute;
  background: url(./assets/images/dashboard/circle_01.png)left top;
  background-repeat: no-repeat;
  left: -100px;
  width: 100%;
  height: 100%;
  z-index: -1; */
}
.dashboard_Section_1 .dashboard_user_account {
  min-height: auto;
  gap: 15px;
  padding: 20px 20px;
}
/* .dashboard_Section_1 .dashboard_user_account::before {
  width: 102%;
}
.dashboard_Section_1 .dashboard_user_account::before {
  background: linear-gradient(
    150deg,
    rgba(0, 69, 137, 1) 0%,
    rgba(0, 23, 46, 1) 45%,
    rgba(0, 69, 137, 1) 100%
  );
} */
/* section_2 */
.dashboard_Section_1 {
  padding-bottom: 50px;
}
.dashboard_upgrade h6 {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  margin-bottom: 0px;
}
.dashboard_upgrade p {
  font-weight: 800;
  font-size: 18px;
  color: #f3d907;
  margin-bottom: 0px;
}
.join_btn {
  border-radius: 100px;
  padding: 12px 24px;
  box-shadow: 41px 32px 104px -11px #004589, inset 13px 12px 22px 0 rgba(169, 172, 0, 0.57);
  background: linear-gradient(180deg, #d1a10c 0%, #f6dd07 100%);
  border: 1px solid transparent;
  font-weight: 700;
  font-size: 18px;
  text-transform: capitalize;
  color: #001e3b;
}
.join_btn:hover {
  /* box-shadow: inset 13px 12px 22px 0 rgba(169, 172, 0, 0.57); */
  background: linear-gradient(180deg, #f6dd07 0%, #d1a10c 100%);
}
.dashboard_upgrade {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  border: 1px solid #00305f;
border-radius: 8px;
padding: 16px;
background: radial-gradient(93.99% 72.86% at 50% 0%, rgb(6, 44, 82) 0%, rgb(0, 18, 36) 100%) !important;

}
.package_upgrade{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #063b70;
border-radius: 6px;
padding: 12px;
}
.package_upgrade h6{
  margin-bottom: 0px;
}
.rejoined {
  box-shadow: inset 0 -2px 10px 0 #0080ff;
  background: linear-gradient(180deg, #0080ff 0%, #063b70 29.8%, #021931 71.69%, #001224 100%) !important;
  border: 1px solid #43a1ff;
border-radius: 8px;
}
.join_now {
  background: #02294e;
  opacity: 0.5;
}
.join_form_check {
  align-items: center;
}
.join_form_check label {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin-bottom: 0px;
}
.join_form_check .form-check {
  margin-bottom: 0px;
}
.join_form_check .form-check-input:focus {
  box-shadow: none;
}
.join_form_check .form-check-input {
  background-color: transparent !important;
}

.join_form_check .form-check-input:checked {
  background-color: #001224 !important;
  border: 1px solid #1a8ef1;
}
.dashboard_Section_2 {
  padding-bottom: 50px;
  /* padding-top: 30px; */
}
.dashboard_refferals {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.dashboard_stake {
  width: 69%;
height: 100%;
}
.progress_upgrade{
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid #00305f;
  padding: 0px 16px 0px 0px
}
/* dashboard_Section_3 */
.dashboard_Section_3 {
  padding-bottom: 50px;
  position: relative;
  /* overflow: hidden; */
}
.dashboard_Section_3::before {
  /* content: "";
  position: absolute;
  background: url(./assets/images/dashboard/circle_01.png);
  background-repeat: no-repeat;
  top: 0;
  left: -130px;
  width: 100%;
  height: 100%;
  z-index: -1; */
}
.dashboard_Section_3::after {
  /* content: "";
  position: absolute;
  background: url(./assets/images/dashboard/circle_02.png) right center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 352px;
    right: -100px; */
}
/* tree */
.dashboard_tree_sec .input-group {
  border-radius: 50px;

  background: #001e3b;
  border: 1px solid rgba(255, 255, 255, 0.05);

  padding: 8px;
  width: 33%;
  margin: auto;
}
.dashboard_tree_sec .form-control {
  border: none;
  background-color: transparent;
  color: #fff !important;
}
.dashboard_tree_sec .input-group-text {
  border: none;
  background-color: transparent;
}
.dashboard_tree_sec .form-control:focus {
  color: #fff;
  background-color: transparent;
  border-color: none;
  outline: 0;
  box-shadow: none;
}
.dashboard_tree_sec .custom-placeholder::placeholder {
  color: #89c4ff;
  font-size: 14px;
  font-weight: 400;
}

/* tree */
.tree {
  width: 100%;
  height: 100%;
}
.tree ul {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  position: relative;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
}
.tree li {
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 30px 20px 0 20px;
  transition: all 0.5s;
}
.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 18px;
  right: 50%;
  border-top: 2px dashed #004589;
  width: 50%;
  height: 30px;
}
.tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px dashed #004589;
}
.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}
.tree li:only-child {
  padding-top: 0;
}
.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}
.tree li:last-child::before {
  border-right: 2px dashed #004589;
  border-radius: 0 2px 0 0;
}
.tree li:first-child::after {
  border-radius: 2px 0 0 0;
}
.tree ul ul::before {
  content: "";
  position: absolute;
  top: -12px;
  left: 50%;
  border-left: 2px dashed #004589;
  width: 0;
  height: 50px;
  z-index: -1;
}
/* .tree ul ul li{
  position: relative;
}
.tree ul ul li::after{
  content: "";
  position: absolute;
  top: 0px;
  left:0;
  right:0;
  bottom: 0;
 background-color: red;
  width: 10px;
  height: 50px;
  z-index: -1;

} */
.tree li a {
  /* border: 1px solid #1e1e1e; */
  /* padding: 5px 10px; */
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  transition: all 0.5s;
  width: 166px;
  height: 94px;
  margin: 0 auto;
  z-index: 10;
  position: relative;
}
.tree li a:hover,
.tree li a:hover + ul li a {
  /* background: #c8e4f8; */
  /* color: #000; */
  /* border: 1px solid #1E1E1E; */
}
.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before {
  border-color: #004589;
}

.tree p {
  border-radius: 5px;
  padding: 5px;
  width: 10%;
  background: #001224;
  font-weight: 400;
  font-size: 12px;
  color: #89c4ff;
  margin-bottom: 0px;
  margin: auto;
  text-align: center;
}
.second_chart p {
  width: 20%;
  margin-top: -5px;
}
.third_chart p {
  width: 50%;
  margin-top: -12px;
}
/* section_4 */
.dashboard_Section_4::before {
  /* content: "";
  position: absolute;
  background: url(./assets/images/dashboard/circle_01.png);
  background-repeat: no-repeat;
  left:-100px ;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1; */
}
.dashboard_Section_4::after {
  /* content: "";
  position: absolute;
  background: url(./assets/images/dashboard/circle_02.png) bottom right;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  bottom: -100px;
  right: 0; */
}
.dashboard_Section_4 {
  margin-bottom: 50px;
  position: relative;
  z-index: 2;
}
.dashboard_table {
  /* background: #02294e;
  border: 2px solid #00376e;
  border-radius: 20px;
  padding: 24px; */
}

.dashboard_table h6 {
  font-weight: 600;
  font-size: 24px;
  text-transform: capitalize;
  color: #e8cc07;
  font-family: "Outfit", sans-serif;
  margin-bottom: 0px;
}
.dashboard_table .table tbody td {
  background: transparent;
  padding: 16px 0px 16px 12px;
  font-weight: 400;
  font-size: 16px;
  color: #d8ebff;
  white-space: nowrap;
}
.dashboard_table .table tbody tr:hover {
  background: #012343;
}
.dashboard_table .table tbody th {
  background: transparent;
  padding: 16px 0px 16px 12px;
  font-weight: 400;
  font-size: 16px;
  color: #d8ebff;
}
.dashboard_table .table thead {
  border-radius: 10px 10px 0 0 !important;
}
.dashboard_table .table thead th {
  background: #004f9e;
  padding: 12px 0px 12px 12px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  white-space: nowrap;
}
.dashboard_table .table thead th:first-child {
  border-radius: 10px 0px 0px 0px;
}
.dashboard_table .table thead th:last-child {
  border-radius: 0px 10px 0px 0px;
}
.dashboard_table .table thead tr th:nth-child(3) {
  background: #004f9e;
  padding: 12px 82px 12px 12px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}
thead tr th:nth-child(3) {
  padding: 20px; /* Adjust the padding as needed */
}
.table > :not(caption) > * > * {
  border: none;
}
.dashboard_table_1 .table tbody th {
  background: transparent;
  padding: 16px 50px 16px 12px;
}
.referral_table{
  margin-top: 50px;
}
.dashboard_user_account.admin_fee {
  height: auto;
  margin: 0 0 30px;
}
.dashboard_user_account.admin_fee > div {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
}
.dashboard_user_account.admin_fee::before {
  height: 103%;
  width: 101%;
}
.custom_tooltip {
  opacity: 1 !important;
  background: #00264d !important;
  z-index: 10;
}
.custom_tooltip h6 span {
  color: #e8cc07;
}
.admin_fee .form-control {
  border-radius: 10px;
  padding: 8px 15px;
  background: #001224 !important;
  border: 1px solid #001224 !important;
  color: #fff !important;
  box-shadow: none !important;
  font-size: 14px;
}
.admin_fee .form-control::placeholder {
  color: #999999 !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
 /* Firefox  */
input[type=number] {
  -moz-appearance: textfield !important;
}
input.admin_withdraw_amount {
  width: 120px;
}


  @media (max-width: 1399px) {
    .dashboard_user_account h6 {
font-size: 15px;
    }
  }
@media (max-width: 1199px) {
  .dashboard_upgrade {
    flex-direction: column;
    gap: 10px;

  }

  .dashboard_Section::before {
    height: 857px;
  }
  .dashboard_Section .dashboard_user_account {
    gap: 12px;
  }
  .join_now_section {
    width: 70% !important;
  }
  .dashboard_user_account.admin_fee {
    align-items: flex-start;
  }
  .navbar_right {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .dashboard_user_account_1 {
    align-items: center;
    justify-content: center;
    gap: 10px !important;
  }
 
  .dashboard_user_account1::before {
    width: 102% !important;
  }
  .collapse_content .dashboard_user_account{
    margin-top: 15px;
  }
  .stake_period{
    margin-left:8px ;
  }
}
@media (max-width: 991px) {
  .dashboard_Section::before {
    height: 725px;
  }
  .dashboard_user_account {
    align-items: center;
    justify-content: center;
  }
  .dashboard_table .table tbody td {
    padding: 16px 40px 16px 12px;
  }
  .dashboard_user_account {
    margin-top: 20px;
  }
  .join_now_section {
    width: 100% !important;
  }
  .dashboard_Section_2 {
    /* padding-top: 10px; */
  }
  .dashboard_user_account1::before {
    width: 101% !important;
  }
  .dashboard_stake {
    flex-direction: column;
    gap: 10px;
  }
  .dashboard_user_account1{
    align-items: start !important;
  }
  .stake_period{
  margin-left:25px ;
  margin-top: 30px !important;

  }
  .dashboard_mobile_view{
    flex-direction: column;
  }
  .dashboard_user_account p {
    font-size: 14px !important;
  }
  .dashboard_upgrade{
    margin-top: 15px !important;
  }
  .dashboard_user_account span {
    width: 50%;
  }
  .dashboard_user_account .p_tag{
    width: 50%;
  }



}
@media (max-width: 767px) {
  .dashboard_tree_sec .input-group {
    width: 100%;
  }

  .h2_tag {
    /* text-align: center; */
    /* font-size: 28px !important; */
  }
 
  .dashboard_table .table thead tr th:nth-child(3) {
    padding: 12px 0px 12px 12px;
  }

  .dashboard_user_account {
    /* height: auto; */
    padding: 25px 20px !important;
  }
  .join_btn {
    padding: 6px 30px !important;
  }
  .dashboard_ref_link {
    flex-direction: column;
  }
  .dashboard_user_account.admin_fee > div {
    flex-direction: column;
    align-items: center;
  }
  .dashboard_user_account {
    border: 1px solid rgba(0, 69, 137, 1);
    height: auto !important;
  }
  .earned_income{
    height: auto !important;
      }
      .chat_mobile{
        flex-direction: column;
        align-items: center !important;
        gap: 15px;
      }
      .dashboard_stake{
        width: 100% !important;
      }
      .progress_upgrade{
        align-items: center;
        border-bottom: 1px solid #00305f;
        border-right: none !important;
        padding-bottom: 15px;
        gap: 10px;
      }
      .dashboard_user_account1{
        align-items: center !important;
      }
      .stake_period{
        margin-left: 0px !important;
      }
      .dashboard_user_detail{
        height: auto;
      }
      .tree li{
        padding: 0px;
      }
    
}
@media (max-width: 575px) {
  .dashboard_Section {
    /* padding: 50px 0px; */
  }
  .tree li{
    padding: 30px 20px 0 20px;

  }
  .tree ul{
    padding-top: 0px;
  }
  .tree li a {
    width: 50px;
    height: 72px;
  }
  .tree p {
    width: 25%;
    font-size: 10px;
  }
  .second_chart p {
    width: 50%;
  }
  .third_chart p {
    width: 100%;
  }
  .tree img {
    width: 70px;
  }
  .second_chart img {
    width: 50px;
  }
  .third_chart img {
    width: 30px;
  }
  .dashboard_user {
    gap: 15px;
  }
  .dashboard_user p {
    font-size: 14px;
  }
  .dashboard_user h6 {
    font-size: 14px;
  }
  .dashboard_user h6 span {
    font-size: 14px;
  }

  .dashboard_user_account p {
    font-weight: 550;
    font-size: 28px;
  }
  .dashboard_upgrade p {
    font-weight: 550;
    font-size: 16px;
  }
  .join_referral {
    gap: 10px;
  }
  .join_referral p {
    font-size: 12px !important;
  }
  .join_now_page {
    padding: 25px 20px !important;
  }
  .dashboard_users::before {
    background: transparent !important;
  }
  .dashboard_users {
    border: 1px solid rgba(0, 69, 137, 1);
  }
 
  .join_form_check {
    flex-direction: column;
    align-items: flex-start !important;
  }
   .dashboard_user_account{
    text-align: center;
  }

  .dashboard_user_account h6{
    font-size: 14px !important;
  }
  .dashboard_user_account p{
    font-size: 24px;
  }
  .stake_updgrade_chat .CircularProgressbar {
    width: 50% !important;
  }
  .stake_period{
    font-size: 14px !important;
  }
  .dashboard_page .h2_tag {
  font-size: 20px;
  text-transform: uppercase;
  }
  .dashboard_stake{
    text-align: left;
  }
  .dashboard_header{
    padding: 10px 16px;
  }
  .dashboard_table h6{
    font-size: 20px;
    text-transform: uppercase;
  }
  .dashboard_header .rotate{
    width: 22px;
  }
  .dashboard_Section_2 {
    padding-bottom: 30px;
  }
  section{
    padding-bottom: 30px !important;
  }
  .dashboard_Section_4{
    margin-bottom: 0px;
  }
  .dashboard_user_detail .form-label {
    font-size: 14px;
  }
  .dashboard_upgrade h6 {
    font-size: 14px;
  }
  .package_upgrade {
    padding: 6px;
  }
  .join_btn {
    font-size: 14px;
    padding: 6px 20px !important;
  }
.mobile_none{
  display: none !important;
}
.dashboard_user_account {
  padding: 16px !important;
}
.mobile_img_none img{
display: block;
}
.dashboard_Section_1{
  padding-top: 0px;
}
.referral_table{
  margin-top: 30px;
}
.dashboard_Section {
  padding: 50px 0px 0px ;
}
.collapse_content h6{
  font-size: 14px !important;
}
.dashboard_user_account span {
  width: 100%;
}
.dashboard_user_account .p_tag{
  width: 100%;
}
  
}

/* join_now */

.join_now_section {
  width: 50%;
  margin: auto;
  min-height: 562px;
}
.join_now_page {
  border-radius: 20px;
  padding: 50px 65px;
  box-shadow: -10px 10px 15px -6px #000d1a,
    -20px 20px 38px 0 rgba(0, 13, 26, 0.25);
  background: #001e3b;
}
.join_now_page .form-label {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
}
.join_now_page .input-group {
  border-radius: 50px;
  padding: 8px;
  background: #001224;
}
.join_now_page {
  text-align: left;
}
.join_now_page .form-control {
  border: none;
  background-color: transparent !important;
  color: #fff !important;
}
.join_now_page .input-group-text {
  border: none;
  background-color: transparent;
}
.join_now_page .form-control:focus {
  color: #fff;
  background-color: transparent;
  border-color: none;
  outline: 0;
  box-shadow: none;
}
.join_now_page .custom-placeholder::placeholder {
  color: #89c4ff;
  font-size: 13px;
  font-weight: 400;
}
.join_now_page .input-group span {
  cursor: pointer;
}
.join_referral h6 {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin-bottom: 5px;
}
.join_referral p {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin-bottom: 0px;
}
.join_referral {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.join_referral img {
  cursor: pointer;
}
.join_now_btn {
  text-align: center;
}

/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient(90deg, rgb(9 41 73) 0%, rgba(3, 20, 62, 1) 100%);
  border: 1px solid #1d6fbf;
  color: #ffffff;
}

.primary_modal .modal-header {
  border-bottom: 0px solid #2a2a2a;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 1px solid #1d6fbf;
  background-color: transparent;
  color: #ffffff;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.5s;
}

.connect_wallet_button:hover {
  border: 1px solid #a6d3fd;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #f3d907;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.modal-header .btn-close {
  width: 32px;
  height: 32px;
  color: #fff;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
  filter: contrast(0.5) brightness(5.5);
}

.modal-header .btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}
.loadBefore::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgb(10 10 10 / 75%);
  z-index: 1500;
  overflow: hidden;
}
.bg_color{
  border-left: 3.50px solid #0080ff;
padding: 6px 16px;
background: linear-gradient(90deg, rgba(0, 128, 255, 0.2) 0%, rgba(0, 128, 255, 0) 100%);
z-index: 5;
position: absolute;
left: 32px;
bottom: 45px;
}
.bg_color p{
  font-weight: 800;
  font-size:  18px ;
  font-family: "Nunito Sans", sans-serif;
  text-transform: capitalize;
  color: #fff;
  margin-bottom: 0;
  margin: 0;
}
.smart_content{
  justify-content: end;
}
.smart_card_content_1 .bg_color{
  background: linear-gradient(90deg, rgba(232, 204, 7, 0.2) 0%, rgba(232, 204, 7, 0) 100%);
  border-left: 3.50px solid #e8cc07;
}
.smart_card_content_2 .bg_color{
  background: linear-gradient(90deg, rgba(232, 204, 7, 0.2) 0%, rgba(232, 204, 7, 0) 100%);
  border-left: 3.50px solid #e8cc07;
}
.rotate{
  transition: .5s;
}
.rotate[aria-expanded="true"] {
  transform: rotate(90deg); /* Adjust the degree as needed */
  transition: .5s;
}
.collapse_content{
  border: 1px solid #00305f;
border-radius: 0 0 16px 16px;
padding: 16px;
background: #001e3b;
}
.collapse_content h6{
  font-size: 18px;
}
.earned_income{
  justify-content: space-between;
  height: 150px;
}
.rotate[aria-expanded="false"] + .collapse_content .dashboard_header {
  border-radius: 0px; 
}
.dashboard_header.not-expanded {
  border-radius: 16px;
}

.stake_updgrade_chat .CircularProgressbar{
  width: 65% ;
}
.chat_define{
  /* display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between; */

}
.chat_define ul{
  list-style-type: none;
  padding: 0;
}
.chat_define li{
  padding: 4px;
  white-space: nowrap;
}
.dashboard_user_account1{
  align-items:end ;
  justify-content:  space-evenly;
}
.chat_mobile{
  display:flex ;
  align-items: start;

}
.stake_updgrade_chat .CircularProgressbar .CircularProgressbar-path {
  stroke: #E8CC07 !important;
}
.stake_updgrade_chat .CircularProgressbar .CircularProgressbar-text{
  fill: #E8CC07 !important;
    font-size: 18px;
    font-weight: 800;
}
.stake_updgrade_chat .CircularProgressbar .CircularProgressbar-trail{
  stroke:#134B84 !important;
}
.stake_period{
  width: 100%;
  border-radius: 8px;
padding: 8px 16px;
background: #00172e;
margin-top: 50px;

}
.stake_period p{
  white-space: nowrap;
  font-weight: 700;
  font-size: 16px ;
text-align: center;
color: #e8cc07;
}

.package_upgrade_chat .CircularProgressbar .CircularProgressbar-text{
  fill: #E8CC07 !important;
    font-size: 18px;
    font-weight: 800;
    transform: rotate(138deg);
    transform-origin: center;
    
}
.package_upgrade_chat .CircularProgressbar .CircularProgressbar-trail{
  stroke:#134B84 !important;
}
.package_upgrade_chat .CircularProgressbar{
  width: 45% !important;
  stroke-dashoffset: 66.828px;
  rotate: 224deg;
}
.package_display{
  display: flex;
  align-items:center ;
  justify-content: space-between;
}
.package_upgrade_chat .CircularProgressbar .CircularProgressbar-trail {
  stroke-dashoffset: 66.828px;
  stroke-linecap: square;
}
.package_upgrade_chat .CircularProgressbar .CircularProgressbar-path{
  stroke-linecap: square;
  stroke-dashoffset: 66.828px;
  stroke: #E8CC07 !important;

}